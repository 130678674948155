/* Global reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f7f7f7;
    color: #333;
    line-height: 1.6;
    font-size: 16px;
    margin-top: 60px; /* To accommodate fixed Navbar */
}

h2 {
    font-size: 36px;
    color: #333;
}

a {
    text-decoration: none;
    color: inherit;
}
